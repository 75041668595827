import axios from "axios";
import { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import Table from "../../components/table";
import { base_url } from "../../consts";
import "./style.css";

const Subjects = () => {
  const navigate = useNavigate();
  const [Students, setStudents] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [subjectData, setSubjectData] = useState({
    subject_name: "",
    subject_description: "",
  });
  const [modal, setModal] = useState(null);
  const doctorData =
    localStorage.getItem("SubjectSystemAcc") &&
    JSON.parse(localStorage.getItem("SubjectSystemAcc"));
  const getStudents = () => {
    axios
      .get(base_url + "students/select_subject.php")
      .then((res) => {
        setStudents(res?.data?.message);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getStudents();
  }, []);

  const filteredStudents =
    Students &&
    Students?.length &&
    Students?.filter((student) => {
      if (searchText && searchText?.length) {
        const searchValue = searchText.trim().toLowerCase();
        // يتم تحويل النص البحثي إلى حالة صغيرة وتجريده من الفراغات لسهولة المقارنة
        return (
          student &&
          student?.student_code &&
          Object?.values(student).some((value) =>
            value.toString().toLowerCase().includes(searchValue)
          )
        );
      } else {
        return student;
      }
    });

  const headers = [
    {
      label: "أدوات",
      type: "children",
      children: ({ row }) => {
        return (
          <>
            {" "}
            <div className="rowDiv">
             
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href = `/Students/${row?.subject_id}/${row?.subject_name}`;
                }}
              >
              الطلاب
              </button>
            </div>
          </>
        );
      },
    },
    {
      label: "كود المادة",
      dataIndex: "subject_id",
    },
    {
      label: "اسم المادة",
      dataIndex: "subject_name",
    },
    {
      label: "عدد الطلبة",
      dataIndex: "subject_limit",
    },
    {
      label: "وصف المادة",
      dataIndex: "subject_description",
    },
    {
      label: "شعار المادة",
      type: "children",
      children: ({ row }) => {
        return <img src={row?.subject_image} />;
      },
    },
  ];

  useEffect(() => {
    if (!modal) {
      setSubjectData({});
    }
  }, [modal]);

  return (
    <div>
      <header>
        <h1>المواد</h1>
      </header>

 
      <div className="groupSubectsCards">
        <Table headers={headers} body={filteredStudents} />
      </div>

      <Modal
        show={modal == "add"}
        open={modal == "add"}
        onClose={() => setModal(null)}
      >
        <Modal.Header>إضافة مواد</Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <div className="form-group">
                <div className="controls">
                  <input
                    type="text"
                    id="subject_name"
                    className="floatLabel"
                    name="subject_name"
                    placeholder="اسم المادة"
                    value={subjectData?.subject_name}
                    onChange={(e) => {
                      setSubjectData({
                        ...subjectData,
                        subject_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <div className="controls">
                  <textarea
                    placeholder="وصف المادة"
                    value={subjectData?.subject_description}
                    onChange={(e) => {
                      setSubjectData({
                        ...subjectData,
                        subject_description: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="controls">
                <button type="submit">إضافة </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={modal == "edit"}
        open={modal == "edit"}
        onClose={() => setModal(null)}
      >
        <Modal.Header>تعديل مواد</Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <div className="form-group">
                <div className="controls">
                  <input
                    type="text"
                    id="subject_name"
                    className="floatLabel"
                    name="subject_name"
                    placeholder="اسم المادة"
                    value={subjectData?.subject_name}
                    onChange={(e) => {
                      setSubjectData({
                        ...subjectData,
                        subject_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <div className="controls">
                  <textarea
                    placeholder="وصف المادة"
                    value={subjectData?.subject_description}
                    onChange={(e) => {
                      setSubjectData({
                        ...subjectData,
                        subject_description: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="controls">
                <button type="submit">إضافة </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={modal == "delete"}
        open={modal == "delete"}
        onClose={() => setModal(null)}
      >
        <Modal.Header>مسح مواد</Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <div className="form-group">
                <div className="controls">
                  <h2>
                    هل أنت متأكد من مسح المادة ({subjectData?.subject_name}) ؟
                  </h2>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="controls">
                <button type="submit">تأكيد </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Subjects;

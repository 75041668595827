import React, { useState } from 'react';
import "./style.css"
import { base_url } from '../../consts';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Loader } from 'rsuite';
const Home = () => {
  const [emails, setEmails] = useState(['']);

  const addEmailInput = () => {
    setEmails([...emails, '']);
  };

  const removeEmailInput = (index) => {
    if (emails.length === 1) return;
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const [loading, setLoading] = useState(false)


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      const response = await axios.post(base_url + 'students/add_student.php', {
        student_emails: emails.join(","),
      });
      if (response.data.status === "success") {
        localStorage.setItem(
          'ReservationSystemAcc',
          JSON.stringify(response.data.message)
        );
        toast.success(response.data.message)
        setEmails([''])
        setLoading(false)

      } else {
        toast.error(response.data.message);
        setLoading(false)
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoading(false)
    }
  };

  return (
    <div>
      <header>
        <h1>
          <i className="fa fa-book"></i> حجز المواد الدراسية!{" "}
          <i className="fa fa-book"></i>
        </h1>
      </header>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='rowDiv spaceBetween'>
          <label>الايميلات</label>

          <button type="button" className='btn btn-success' onClick={addEmailInput}>+</button>

        </div>
        {emails.map((email, index) => (
          <div className="form-row" key={index}>
            <div className="form-group">
              <div className="controls rowDiv spaceBetween">
                <input
                  type="text"
                  id={`email${index}`}
                  className="floatLabel"
                  name={`email${index}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                />
                <span
                  type="button"
                  className='btn btn-danger'
                  onClick={() => removeEmailInput(index)}
                  disabled={emails.length === 1}
                >
                  -
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className="form-group">
          {!loading ? <button className='btn btn-success'>إضافة الطلاب إلى النظام</button> : <Loader />}
        </div>
      </form>
    </div>
  );
};

export default Home;

import axios from "axios";
import "../../assets/bootstrap.min.css"
import { useEffect, useState } from "react";
import Table from "../../components/table";
import { base_url } from "../../consts";
import "./style.css";
import toast from "react-hot-toast";
import { Loader, Modal } from "rsuite";
import CreatableSelect from 'react-select/creatable';
import { useParams } from "react-router-dom";

const StudentsSubjects = () => {
  const [Students, setStudents] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [modal, setModal] = useState(null);
  const [cities, setCeties] = useState([]);
  const { id } = useParams()
  const [filteredCountries, setFilteredCountries] = useState([]);
  const getStudents = () => {
    axios
      .post(base_url + "students/select_subject_students.php", { subject_id: id })
      .then((res) => {
        setStudents(res?.data?.message);
      })
      .catch((err) => err);
  };
  useEffect(() => {
    axios
      .get(base_url + "/students/select_subject.php")
      .then((res) => {
        setCeties(res?.data?.message);

      })
      .catch((err) => err);
  }, []);

  useEffect(() => {
    getStudents();
  }, []);

  const filteredStudents = Students?.filter((student) => {
    if (searchText && searchText?.length) {
      const searchValue = searchText.trim().toLowerCase();
      // يتم تحويل النص البحثي إلى حالة صغيرة وتجريده من الفراغات لسهولة المقارنة
      return (
        student &&
        student?.student_id &&
        Object?.values(student).some((value) =>
          value?.toLowerCase()?.includes(searchValue)
        )
      );
    } else {
      return student;
    }
  });

  const confirmed = (type, studentId) => {
    axios
      .post(base_url + "", {
        student_id: studentId,
        status: type, //'confirmed', 'problem'
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          getStudents();
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        toast.error("الانترنت به مشكلة");
      });
  };

  const headers = [
    {
      label: "اختيار",
      type: "children",
      children: ({ row }) => {
        return (
          <>
            {" "}
            <div className="rowDiv">
              <input
                type="checkbox"
                checked={selectedStudents.some(item => row.student_id === item)}
                value={row.student_id}
                onChange={(e) => {
                  if (selectedStudents.some(item => item === row.student_id)) {
                    setSelectedStudents(selectedStudents.filter(item => item !== row.student_id));
                  } else {
                    setSelectedStudents([...selectedStudents, row.student_id]);
                  }
                }}
              />

            </div>
          </>
        );
      },
    },
    {
      label: "أدوات",
      type: "children",
      children: ({ row }) => {
        return (
          <>
            {" "}
            <div className="rowDiv">
              <button
                className="btn btn-danger"
                onClick={() => {
                  setModal("disActive");
                  setSelectedCity(row?.student_subject_id
                  )

                }}
              >
                إلغاء التفعيل
              </button>
              {/* <button
                className="btn btn-primary"
                onClick={() => {
                  axios.post(base_url + "students/select_subject_students.php", { student_id: row?.student_id }).then(res => {
                    if (res?.data?.status == "success") {
                      setModal("active");

                      setSelectedCity(res?.data?.message)
                    }
                  })
                }}
              >
                تفعيل
              </button> */}
            </div>
          </>
        );
      },
    },

    {
      label: "كود الطالب",
      dataIndex: "student_id",
    },
    {
      label: "اسم الطالب",
      dataIndex: "student_name",
    },
    {
      label: "إيميل الطالب",
      dataIndex: "student_email",
    },


  ];

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    // e.preventDefault();
    try {
      const response = await axios.post(base_url + 'students/add_subscription.php', {
        student_ids: selectedStudents.join(","),
        subject_id: selectedCity?.subject_id
      });
      if (response.data.status === "success") {

        toast.success(response.data.message)

        setLoading(false)

      } else {
        toast.error(response.data.message);
        setLoading(false)
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoading(false)
    }
  };


  const handleDisSubmit = async () => {
    setLoading(true)
    // e.preventDefault();
    try {
      const response = await axios.post(base_url + 'students/un_subscribe.php', {
        student_subject_id: selectedCity
      });
      if (response.data.status === "success") {

        toast.success(response.data.message)
        setModal(null)
        getStudents()
        setLoading(false)

      } else {
        toast.error(response.data.message);
        setLoading(false)
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoading(false)
    }
  };

  return (
    <div>
      <header>
        <h1>الطلاب</h1>
      </header>


      <div className="form-group">
        <div className="controls">
          <p>ابحث هنا</p>
          <input
            type="text"
            placeholder="ابحث هنا"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchInput"
          />
        </div>
      </div>

      {
        selectedStudents && selectedStudents?.length ?
          <button
            style={{ margin: "20px" }}
            className="btn btn-primary"
            onClick={() => {
              setModal("active");
            }}
          >
            تفعيل
          </button> : null
      }
      <div className="groupSubectsCards">
        <Table headers={headers} body={filteredStudents} />
      </div>
      <Modal
        show={modal == "disActive"}
        open={modal == "disActive"}
        onHide={() => setModal(null)}
      >
        <Modal.Header>إلغاء التفعيل</Modal.Header>
        <Modal.Body>
          <div className="controls">
            <div className="form-row">
              <div className="form-group">
                <p htmlFor="city" style={{ marginBottom: '10px' }}>
                  هل أنت متأكد ?
                </p>

              </div>
            </div>
          </div>
          <Modal.Footer style={{ display: "flex", gap: "10px" }}>
            <div className="btn btn-primary" onClick={() => setModal(null)}>
              إلغاء
            </div>


            {!loading ? <button className='btn btn-success' onClick={() => handleDisSubmit()}>  تأكيد </button> : <Loader />}

          </Modal.Footer>
        </Modal.Body>
      </Modal>


      <Modal
        show={modal == "active"}
        open={modal == "active"}
        onHide={() => setModal(null)}
        toggle={() => setModal(null)}

      >
        <Modal.Header>تفعيل مواد</Modal.Header>
        <Modal.Body>
          <div className="controls">
            <div className="form-row">
              <div className="form-group">
                <p htmlFor="city" style={{ marginBottom: '10px' }}>
                  المواد
                </p>
                <CreatableSelect
                  id="city"
                  placeholder={"اختر المواد"}
                  value={selectedCity}
                  onChange={setSelectedCity}
                  // isMulti
                  options={
                    cities &&
                    cities.map((city) => ({
                      value: city.subject_id,
                      label: city.subject_name,
                    }))
                  }
                  blurInputOnSelect={false}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue ? "لا توجد خيارات" : null
                  }
                />
              </div>
            </div>
          </div>
          <Modal.Footer style={{ display: "flex", gap: "10px" }}>
            <div className="btn btn-primary" onClick={() => setModal(null)}>
              إلغاء
            </div>
            {!loading ? <button className='btn btn-success' onClick={() => handleSubmit()}>  تأكيد </button> : <Loader />}

          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StudentsSubjects;

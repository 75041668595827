import React, { useState } from 'react';
import "./style.css";
import axios from 'axios';
import { base_url } from '../../consts';
import PopUp from '../../components/popup';
import toast from 'react-hot-toast';

const Inform = () => {
  const [userData, setUserData] = useState({});
  const [studentCode, setStudentCode] = useState('');
  const [password, setPassword] = useState('');
  const [cancel, setCancel] = useState(false);
  const [confirm, setConfirm] = useState(false);
  // setConfirm

  const fetchData = (code) => {
    axios
      .post(base_url + "/get_user_data.php", {
        student_code: code,
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.status == "success") setUserData(res?.data?.message);
        else {
          alert("لا يوجد طالب بهذا الكود");
          setUserData(null);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        alert('حدث خطأ أثناء جلب بيانات الطالب.');
      });
  };
  const cancelSub = () => {
    if (password != "12312") {
      return alert("الباسوورد غير صحيح");
    }
    axios
      .post(base_url + "/cancele_student.php", {
        student_code: cancel?.student_code,
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          fetchData(studentCode);
          setCancel(false);
          alert(res?.data?.message);
        } else {
          alert(res?.data?.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        alert("الانترنت به مشكلة");
      });
  };

  const confirmed = (type, studentId) => {
    axios
      .post(base_url + "/confirm_student.php", {
        student_id: studentId,
        status: type, //'confirmed', 'problem'
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          fetchData(studentCode);
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        toast.error("الانترنت به مشكلة");
      });
  };

  return (
    <div>
      <header>
        <h1>استعلام عن طالب</h1>
      </header>
      <form
        className="form-group inform"
        onSubmit={(e) => {
          e.preventDefault();
          fetchData(studentCode);
        }}
      >
        <label htmlFor="">استعلام عن طالب</label>
        <div className="controls">
          <input
            type="text"
            id="name"
            className="floatLabel"
            name="name"
            placeholder="كود الطالب"
            value={studentCode}
            onChange={(e) => setStudentCode(e.target.value)}
          />
          <button>استعلام</button>
        </div>
      </form>
      {userData &&
        typeof userData === "object" &&
        Object.keys(userData).length > 0 && (
          <div className="student-card">
            <div className="student-card-row">
              <div className="student-card-label">كود الطالب:</div>
              <div className="student-card-value">{userData.student_code}</div>
            </div>
            <div className="student-card-row">
              <div className="student-card-label">اسم الطالب:</div>
              <div className="student-card-value">{userData.student_name}</div>
            </div>
            <div className="student-card-row">
              <div className="student-card-label">اسم المركز:</div>
              <div className="student-card-value">{userData.markz_name}</div>
            </div>
            <div className="student-card-row">
              <div className="student-card-label">اسم المنطقة:</div>
              <div className="student-card-value">{userData.area_name}</div>
            </div>
            <div className="student-card-row">
              <div className="student-card-label">رقم الهاتف:</div>
              <div className="student-card-value">{userData.phone}</div>
            </div>
            <div className="student-card-row">
              <div className="student-card-label">رقم ولي الأمر:</div>
              <div className="student-card-value">{userData.parent_phone}</div>
            </div>
            {userData.ch_group_name && userData.ch_group_name?.length && (
              <div className="student-card-row">
                <div className="student-card-label">اسم مجموعة الكيمياء:</div>
                <div className="student-card-value">
                  {userData.ch_group_name}
                </div>
              </div>
            )}
            {userData.ch_group_desc && userData.ch_group_desc?.length && (
              <div className="student-card-row">
                <div className="student-card-label">وصف مجموعة الكيمياء:</div>
                <div className="student-card-value">
                  {userData.ch_group_desc}
                </div>
              </div>
            )}
            {userData.ph_group_name && userData.ph_group_name?.length && (
              <div className="student-card-row">
                <div className="student-card-label">اسم مجموعة الفيزياء:</div>
                <div className="student-card-value">
                  {userData.ph_group_name}
                </div>
              </div>
            )}
            <div className="student-card-row">
              <div className="student-card-label">الاشتراك :</div>
              <div className="student-card-value">
                <p
                  style={{
                    color: parseInt(userData?.cancel) ? "red" : "green",
                  }}
                >
                  {parseInt(userData?.cancel) ? "ملغي" : "مشترك"}
                </p>
              </div>
            </div>
            {userData?.status == "confirmed" ||
            userData?.status == "problem" ? (
              <span>{userData?.status}</span>
            ) : (
              <div className="rowDiv">
                <button
                  className="btn btn-success"
                  onClick={() => confirmed("confirmed", userData?.student_id)}
                >
                  تم التأكيد
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => confirmed("problem", userData?.student_id)}
                >
                  مشكلة
                </button>
              </div>
            )}{" "}
            {!parseInt(userData.cancel) && (
              <button
                className="btn btn-danger"
                onClick={() => setCancel(userData)}
              >
                إلغاء الاشتراك
              </button>
            )}
            <PopUp open={cancel} title={"إلغاء الاشتراك "} setOpen={setCancel}>
              <p>هل أنت متأكد من إلغاء الاشتراك</p>
              <div className="rowDiv">
                <div className="form-group inform">
                  <label htmlFor="">استعلام عن طالب</label>
                  <div className="controls">
                    <input
                      type="text"
                      id="name"
                      className="floatLabel"
                      name="name"
                      placeholder="الباسوورد"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <button className="btn btn-danger" onClick={() => cancelSub()}>
                  تأكيد
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setCancel(false);
                  }}
                >
                  إلغاء
                </button>
              </div>
            </PopUp>
          </div>
        )}
    </div>
  );
};

export default Inform;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { base_url } from '../../consts';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(base_url + 'authentication/doctor_login.php', {
        email: email,
        password: password,
      });
      console.log(response.data);
      if (response.data.status === "success") {
        localStorage.setItem(
          'ReservationSystemAcc',
          JSON.stringify(response.data.message)
        );
        alert("تم تسجيل الدخول بنجاح");
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  useEffect(() => {
    const floatLabel = (inputType) => {
      const inputs = document.querySelectorAll(inputType);
      inputs.forEach((input) => {
        const label = input.nextElementSibling;
        input.addEventListener('focus', () => {
          label.classList.add('active');
        });
        input.addEventListener('blur', () => {
          if (input.value === '' || input.value === 'blank') {
            label.classList.remove('active');
          }
        });
      });
    };
    floatLabel('.floatLabel');
  }, []);

  return (
    <div>
      <header>
        <h1>تسجيل الدخول</h1>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <div className="controls">
              <input
                type="text"
                id="email"
                className="floatLabel"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email"> البريد الالكتروني</label>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="controls">
              <input
                type="text"
                id="password"
                className="floatLabel"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="password">كلمة السر</label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="controls">
            <button type="submit">دخول</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LogIn;

import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import DefaultLayout from "./layouts/defaultLayout";
import Groups from "./pages/Groups";
import Inform from "./pages/inform";
import LogIn from "./pages/login";
import Students from "./pages/students";
import { Toaster } from "react-hot-toast";
import "./index.css";
import 'rsuite/dist/rsuite.min.css';
import Subjects from "./pages/subjects";
import StudentsSubjects from "./pages/studentssubjects";

function App() {
  return (
    <div className="App">
      <DefaultLayout>
        {localStorage?.getItem("ReservationSystemAcc") ? (
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/Students" element={<Students />} />
            <Route path="/Subjects" element={<Subjects />} />
            <Route path="/Students/:id/:name" element={<StudentsSubjects />} />
            <Route path="/groups">
              <Route path="" element={<Groups />} />
            </Route>
            <Route path="/Inform" element={<Inform />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<LogIn />} />{" "}
          </Routes>
        )}
      </DefaultLayout>
      <Toaster containerClassName="toaster" />
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import axios from "axios";
import { base_url } from "../../consts";
import "./style.css";
import PopUp from "../../components/popup";

const Groups = () => {
  const [editData, setEditData] = useState(null);
  const [groupsData, setGroupsData] = useState({
    subjects: [],
    groups: [],
  });
  const [subLoading, setSubLoading] = useState(false);

  useEffect(() => {
    selectSubjs();
  }, []);

  const selectSubjs = async () => {
    setSubLoading(true);
    try {
      const res = await axios.get(base_url + "/select_groups.php");
      const data = res?.data?.message;
      const subjects = [];
      const groups = [];
      data.forEach((item) => {
        const subjectIndex = subjects.findIndex(
          (subj) => subj.name === item.type
        );
        if (subjectIndex === -1) {
          subjects.push({
            name: item.type,
            id: subjects.length + 1,
          });
        }
        groups.push({
          name: item.group_name,
          subjectId: subjects.find((subj) => subj.name === item.type)?.id,
          groupId: parseInt(item.group_id),
          schedule: item.group_desc,
        });
      });
      setGroupsData({ subjects, groups });
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setSubLoading(false);
    }
  };

  return (
    <div>
      <header>
        <h1>المجموعات</h1>
      </header>
      <div className="groupSubectsCards">
        {groupsData.subjects.map((subject) => (
          <div className="subjectCard" key={subject.id}>
            <h1>{subject.name == "ch" ? "كيمياء" : "فيزياء"}</h1>
            <div className="cards">
              {groupsData.groups
                .filter((group) => group.subjectId === subject.id)
                .map((group) => (
                  <div className="card" key={group.groupId}>
                    <div className="card-details">
                      <div className="card-detail">
                        <p className="card-detail-label">{group.name}</p>
                        <p className="card-detail-value">{group.schedule}</p>
                      </div>
                      {/* <button
                        className="btn btn-success"
                        onClick={() => setEditData(group)}
                      >
                        تعديل
                      </button> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      <PopUp open={editData} title={"تعديل المجموعة "} setOpen={setEditData}>
        <form>
          <div className="form-row">
            <div className="form-group">
              <div className="controls">
                <input
                  type="text"
                  id="name"
                  className="floatLabel"
                  name="name"
                  placeholder="اسم المجموعة"
                  value={editData?.name}
                  onChange={(e) =>
                    setEditData({ ...editData, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <div className="controls">
                <input
                  type="text"
                  id="name"
                  className="floatLabel"
                  name="name"
                  placeholder="الموعد"
                  autoComplete
                  value={editData?.schedule}
                  onChange={(e) =>
                    setEditData({ ...editData, schedule: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <button>تعديل</button>
        </form>
      </PopUp>
    </div>
  );
};

export default Groups;
